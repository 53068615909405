import { createSvgIcon } from '@mui/material';

//todo create util to generate icons from Assets | create enum for icons | etc
const BurgerIcon = createSvgIcon(
  <svg width="32" height="20" viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.5 2H32L31.5 4H2L2.5 2Z" fill="#272727" />
    <path d="M5.5 9H27L26.5 11H5L5.5 9Z" fill="#16C172" />
    <path d="M0.5 16H30L29.5 18H0L0.5 16Z" fill="#272727" />
  </svg>,
  'Burger',
);

export default BurgerIcon;

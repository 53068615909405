import { FC } from 'react';
import Link from 'next/link';
import { Stack, Typography } from '@mui/material';

export const Logo: FC = () => {
  if (typeof window === 'undefined') return null;

  const shouldRedirectToHome = !['/payment', '/second-step'].includes(window.location.pathname);

  return (
    <Link href={shouldRedirectToHome ? '/' : window.location.pathname}>
      <Stack direction={'row'}>
        <p
          style={{
            fontFamily: '"Fugaz One", sans-serif',
            fontSize: '22px',
            fontWeight: '600',
            color: '#272727',
          }}>
          <i>CROSS</i>
        </p>
        <p
          style={{
            fontFamily: '"Fugaz One", sans-serif',
            fontSize: '22px',
            fontWeight: '600',
            color: '#16C172',
          }}>
          <i>CRYPTOS</i>
        </p>
      </Stack>
    </Link>
  );
};

import { create } from 'zustand';
import { message } from 'antd';
import axios from '../../api/axios';

interface UserData {
  accessToken: string;
}

interface UserStore {
  token: UserData['accessToken'] | null;
  login: (username: string, password: string) => Promise<void>;
}

const useUserStore = create<UserStore>((set: any) => {
  const storedToken = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null;

  return {
    token: storedToken || null,
    login: async (username: string, password: string) => {
      try {
        const response = await axios.post('auth/login', {
          username,
          password,
        });
        if (response.status === 201) {
          const data: UserData = response.data;
          set({ token: data.accessToken });
          localStorage.setItem('accessToken', data.accessToken);
          window.location.pathname = '/main-editor';
        } else {
          message.error('Аутентификация не удалась');
        }
      } catch (error: any) {
        message.error('Аутентификация не удалась');
        console.error('Аутентификация не удалась:', error.message);
      }
    },
  };
});

export default useUserStore;

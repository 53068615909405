'use client';

import './globals.css';
import Head from 'next/head';
import { useEffect } from 'react';
import Header from '@/shared/organisms/Header';
import { Stack, ThemeProvider, createTheme } from '@mui/material';

export default function RootLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const MainTheme = createTheme({
    typography: {
      fontFamily: 'Geologica',
    },
  });

  useEffect(() => {
    if (process.env.NEXT_MANUAL_SIG_HANDLE) {
      const handleSigterm = () => {
        console.log('Received SIGTERM: cleaning up');
        process.exit(0);
      };
      const handleSigint = () => {
        console.log('Received SIGINT: cleaning up');
        process.exit(0);
      };

      process.on('SIGTERM', handleSigterm);
      process.on('SIGINT', handleSigint);

      return () => {
        process.off('SIGTERM', handleSigterm);
        process.off('SIGINT', handleSigint);
      };
    }
  }, []);

  return (
    <html lang="en">
      <Head>
        <title>CrossCryptos</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content="Exchange crypto app" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <body>
        <ThemeProvider theme={MainTheme}>
          <Header />
          <Stack spacing={6} sx={{ maxWidth: '1424px', margin: '0 auto', p: '16px' }}>
            {children}
          </Stack>
        </ThemeProvider>
      </body>
    </html>
  );
}

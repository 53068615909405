'use client';

import Link from 'next/link';
import { FC, useState } from 'react';
import { AppBar, Stack, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';

import { Logo } from '../atoms/Logo';
import BurgerIcon from '../atoms/Icon/BurgerIcon';
import { Btn } from '../atoms/Btn/Btn';
import useUserStore from '@/app/adminPanel/authorizationStore';

const paths: { id: number; name: string; route: string }[] = [
  {
    id: 1,
    name: 'FAQ',
    route: '/faq',
  },
];

const pathsAdmin: { id: number; name: string; route: string }[] = [
  {
    id: 1,
    name: 'Main editor',
    route: '/main-editor',
  },
  {
    id: 2,
    name: 'Leads',
    route: '/leads',
  },

  {
    id: 3,
    name: 'FAQ',
    route: '/faq',
  },
];

const Header: FC = () => {
  const { token } = useUserStore();
  const routesMenu = token ? pathsAdmin : paths;
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleBurgerClick = () => {
    setModalOpen(true);
  };

  return (
    <AppBar
      position={'static'}
      color={'transparent'}
      sx={{
        py: { xs: '16px', md: '24px' },
        boxShadow: 'none',
        maxWidth: '1395px',
        margin: '0 auto',
        p: { xs: '16px', md: '38px 0px' },
      }}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Logo />
        <Stack direction={'row'} spacing={6} sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Stack direction={'row'} alignItems={'center'} spacing={6}>
            {routesMenu.map((path: { id: number; name: string; route: string }) => (
              <Link href={path.route} key={path.id}>
                <Typography
                  sx={{
                    '&:hover': { color: '#16C172' },
                    color: '#272727',
                  }}>
                  {path.name}
                </Typography>
              </Link>
            ))}
          </Stack>
          <Link href="mailto:support@crosscryptos.com">
            <Typography
              sx={{
                fontSize: '14px',
                color: '#16C172',
                '&:hover': { color: '#272727' },
              }}>
              support@crosscryptos.com
            </Typography>
          </Link>
        </Stack>
        <BurgerIcon sx={{ display: { xs: 'flex', md: 'none' } }} onClick={handleBurgerClick} />
      </Stack>

      <Dialog
        sx={{
          textAlign: 'center',
          top: '-40%',
        }}
        open={modalOpen}
        onClose={() => handleCloseModal()}
        fullWidth>
        <DialogTitle sx={{ color: '#272727', fontSize: '22px' }}>MENU OF SERVICE</DialogTitle>
        <DialogContent sx={{ color: '#272727' }}>
          {paths.map((path) => (
            <Link href={path.route} key={path.id}>
              <Btn
                sx={{ color: '#272727', mb: '10px' }}
                handleButtonClick={() => handleCloseModal()}>
                {path.name}
              </Btn>
            </Link>
          ))}
          <Link href="mailto:support@crosscryptos.com" onClick={() => handleCloseModal()}>
            <Btn
              sx={{
                px: 3,
                py: 2,
                width: '75%',
                borderRadius: '8px',
                border: '1px solid #16C172',
                color: '#16C172',
                fontSize: '14px',
              }}>
              Get Support
            </Btn>
          </Link>
        </DialogContent>
      </Dialog>
    </AppBar>
  );
};

export default Header;
